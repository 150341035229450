<template>
  <FormModal
    next-btn-text="Vergelijk nu"
    show-back-btn
    title="Vul je gegevens aan"
  >
    <template #body>
      <ContactInformationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import ContactInformationFormStep from '~/components/form/steps/contactInformation/ContactInformationFormStep'

export default {
  components: {
    ContactInformationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Je gegevens',
      headDescription:
        'Vul je gegevens aan, zodat we je in contact kunnen brengen met de best geschikte leveranciers uit jouw regio.',
      path: '/offertes-aanvragen/je-gegevens',
      progressValue: 66,
      checkoutStep: 2,
    }
  },
}
</script>
